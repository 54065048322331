import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import { Menus } from "../config/data";

import Container from "./Container";

export class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow">
        <Container>
          <Link className="navbar-brand font-weight-bold" to="/">
            <i className="fa fa-code"></i> منتجع الشاطئ
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="hamburger-menu">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div
            className="collapse navbar-collapse mt-2 mt-lg-0"
            id="navbarContent"
          >
            <ul className="navbar-nav px-0 mr-0 mr-lg-4">
              {Menus.MainMenu.map((menuItem, index) => (
                <li className="nav-item" key={index}>
                  <NavLink
                    exact
                    className="nav-link"
                    to={menuItem.slug}
                    activeClassName="active"
                  >
                    {menuItem.item}
                  </NavLink>
                </li>
              ))}
            </ul>
            {process.env.REACT_APP_EN_SITE && (
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a
                    className="nav-link language-toggle"
                    href={`${
                      process.env.REACT_APP_EN_SITE ||
                      "https://beachresort.demo.youcefdeveloper.com"
                    }`}
                  >
                    English
                  </a>
                </li>
              </ul>
            )}
          </div>
        </Container>
      </nav>
    );
  }
}

export default Navbar;
