import React from "react";
import { Link } from "react-router-dom";

import Container from "./Container";

const RoomInfo = ({ room }) => {
  const {
    name,
    description,
    capacity,
    size,
    price,
    extras,
    breakfast,
    pets
  } = room;

  return (
    <Container>
      <div className="d-lg-flex justify-content-between align-items-center pb-3 mt-5 mb-5">
        <div className="mb-4 mb-lg-0">
          <h1 className="font-weight-bold text-capitalize">{name}</h1>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/rooms" className="btn btn-lg btn-primary">
            كل الغرف
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 order-lg-2 mb-5">
          <h4 className="font-weight-bold mb-3">معلومات</h4>
          <p className="mb-2">السعر: {price} دولارًا</p>
          <p className="mb-2">المساحة: {size} قدم مربع</p>
          <p className="mb-2">
            السعة:{" "}
            {capacity} {`${capacity >= 2 && capacity <= 10 ? "أشخاص" : "شخص"}`}
          </p>
          <p className="mb-2">{pets ? "" : "غير"} مسموح بالحيوانات الأليفة</p>
          {breakfast && <p className="mb-2">فطور الصباح مجاني</p>}
        </div>
        <div className="col-lg-8 order-lg-1 mb-5">
          <h4 className="font-weight-bold mb-3">تفاصيل</h4>
          {description}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mb-5">
          <h5 className="font-weight-bold mb-3">إضافات</h5>
          <ul className="pl-4">
            {extras.map((extra, index) => (
              <li key={index}>{extra}</li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default RoomInfo;
