import React from "react";

import { paging } from "../utils";

function Pagination({
  itemsPerPage,
  totalItems,
  currentPage,
  paginate,
  prevNext,
  theme
}) {
  const pages = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      {theme === "default" ? (
        <nav>
          <ul className="pagination mb-0">
            {prevNext && (
              <>
                <li className={`page-item ${currentPage === 1 && "disabled"}`}>
                  {currentPage === 1 ? (
                    <span className="page-link">&#8249;</span>
                  ) : (
                    <a
                      href="!#"
                      className="page-link ml-0"
                      onClick={(e) => {
                        e.preventDefault();
                        paginate(currentPage - 1);
                      }}
                    >
                      &#8249;
                    </a>
                  )}
                </li>
              </>
            )}
            {paging(currentPage, pages).map((page, i) =>
              page === "..." ? (
                <li className="page-item page-item-dots disabled" key={i}>
                  <span className="page-link">...</span>
                </li>
              ) : (
                <li
                  key={i}
                  className={`page-item ${page === currentPage && "active"}`}
                >
                  <a
                    href="!#"
                    className="page-link"
                    onClick={(e) => {
                      e.preventDefault();
                      paginate(page);
                    }}
                  >
                    {page}
                  </a>
                </li>
              )
            )}
            {prevNext && (
              <>
                <li
                  className={`page-item ${currentPage === pages && "disabled"}`}
                >
                  {currentPage === pages ? (
                    <span className="page-link">&#8250;</span>
                  ) : (
                    <a
                      href="!#"
                      className="page-link ml-0"
                      onClick={(e) => {
                        e.preventDefault();
                        paginate(currentPage + 1);
                      }}
                    >
                      &#8250;
                    </a>
                  )}
                </li>
              </>
            )}
          </ul>
        </nav>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {currentPage === 1 ? (
              <button
                className="btn btn-default btn-pointer disabled"
                title="الصفحة السابقة"
              >
                <span className="d-none d-md-inline-block">
                  &#8249;&nbsp;&nbsp;السابق
                </span>
                <span className="d-inline-block d-md-none btn-chevron">
                  <i className="bi bi-chevron-left"></i>
                </span>
              </button>
            ) : (
              <button
                className="btn btn-default"
                title="الصفحة السابقة"
                onClick={(e) => {
                  e.preventDefault();
                  paginate(currentPage - 1);
                }}
              >
                <span className="d-none d-md-inline-block">
                  &#8249;&nbsp;&nbsp;السابق
                </span>
                <span className="d-inline-block d-md-none btn-chevron">
                  <i className="bi bi-chevron-left"></i>
                </span>
              </button>
            )}
          </div>
          <div className="mx-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn btn-pointer pr-0">الصفحة</div>
              <div>
                <select
                  className="custom-select ml-4 pl-3 pr-3"
                  onChange={(e) => {
                    e.preventDefault();
                    paginate(parseInt(e.target.value));
                  }}
                  value={currentPage}
                >
                  {[...Array(pages)].map((_, i) => {
                    return (
                      <option value={i + 1} key={i}>
                        {i + 1}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="btn btn-pointer pl-0">من {pages}</div>
            </div>
          </div>
          <div>
            {currentPage === pages ? (
              <button
                className="btn btn-default btn-pointer disabled"
                title="الصفحة التالية"
              >
                <span className="d-none d-md-inline-block">
                  التالي&nbsp;&nbsp;&#8250;
                </span>
                <span className="d-inline-block d-md-none btn-chevron">
                  <i className="bi bi-chevron-right"></i>
                </span>
              </button>
            ) : (
              <button
                className="btn btn-default"
                title="الصفحة التالية"
                onClick={(e) => {
                  e.preventDefault();
                  paginate(currentPage + 1);
                }}
              >
                <span className="d-none d-md-inline-block">
                  التالي&nbsp;&nbsp;&#8250;
                </span>
                <span className="d-inline-block d-md-none btn-chevron">
                  <i className="bi bi-chevron-right"></i>
                </span>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

Pagination.defaultProps = {
  prevNext: true,
  theme: "default"
};

export default Pagination;
