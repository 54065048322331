import React, { Component } from "react";
import Container from "./Container";

import Title from "./Title";

export class Services extends Component {
  state = {
    services: [
      {
        icon: "cup-straw",
        title: "كوكتيلات مجانية",
        info: "لوريم ايبسوم دولار سيت أميت كونسيكتيتور أدايبا يسكينج أليايت سيت دو أيوسمود تيمبور"
      },
      {
        icon: "tv",
        title: "أفلام مجانية",
        info: "لوريم ايبسوم دولار سيت أميت كونسيكتيتور أدايبا يسكينج أليايت سيت دو أيوسمود تيمبور"
      },
      {
        icon: "truck",
        title: "مكوك مجاني",
        info: "لوريم ايبسوم دولار سيت أميت كونسيكتيتور أدايبا يسكينج أليايت سيت دو أيوسمود تيمبور"
      },
      {
        icon: "trophy",
        title: "بلياردو مجاني",
        info: "لوريم ايبسوم دولار سيت أميت كونسيكتيتور أدايبا يسكينج أليايت سيت دو أيوسمود تيمبور"
      }
    ]
  };

  componentDidMount() {
    let services = [...this.state.services];
    let service = { ...services[0], title: "كوكتيلات مجانية" };
    services[0] = service;
    this.setState({ services });
  }
  render() {
    return (
      <div id="services">
        <div className="band band-fat bg-light d-none d-md-block">
          <Container>
            <Title title="خدمات" />
            <div className="row">
              {this.state.services.map((service, index) => (
                <div className="col-md-6 col-lg-3" key={index}>
                  <div className="card card-tranparent">
                    <div className="card-body text-center">
                      <i className={`bi bi-${service.icon} display-4`}></i>
                      <p className="h4 font-weight-bold mt-4">
                        {service.title}
                      </p>
                      <p>{service.info}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Services;
