import React from "react";

import Room from "./Room";

const RoomsList = ({ rooms }) => {
  if (rooms.length === 0)
    return (
      <div className="container-overlap">
        <div className="card card-noborder shadow">
          <div className="card-body my-4 lead text-center font-weight-bold">
            للأسف لا توجد غرف مطابقة لمعايير البحث الخاصة بك
          </div>
        </div>
      </div>
    );

  return (
    <div className="row">
      {rooms.map((room) => {
        return (
          <div className="col-md-6 col-lg-4" key={room.id}>
            <Room room={room} />
          </div>
        );
      })}
    </div>
  );
};

export default RoomsList;
