import React from "react";

import Container from "./Container";

const Footer = () => {
  return (
    <>
      <footer>
        <Container>
          <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
            <p className="text-small">مصمم من طرف يوسف المطور &copy; 2021</p>
            <p className="text-small float-lg-right">
              التكنولوجيات&nbsp;&nbsp;
              <span className="badge badge-soft ml-1">Bootstrap</span>
              <span className="badge badge-soft ml-1">Contentful</span>
              <span className="badge badge-soft ml-1">React</span>
              <span className="badge badge-soft">Vercel</span>
            </p>
          </div>
        </Container>
      </footer>
      <div className="back-top" id="backTop" />
    </>
  );
};

export default Footer;
