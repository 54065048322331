import React from "react";
import { Link } from "react-router-dom";

import Container from "../components/Container";

const RoomNotFound = () => {
  return (
    <Container marginTop="mt-5">
      <div className="error">
        <h1 className="font-weight-bold mt-5 mb-5">الغرفة غير موجودة!</h1>
        <Link to="/rooms" className="btn btn-lg btn-primary">
          العودة إلى الغرف
        </Link>
      </div>
    </Container>
  );
};

export default RoomNotFound;
