import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import defaultImg from "../assets/img/defaultImg.jpg";

const Room = ({ room }) => {
  const { name, slug, images, price } = room;

  return (
    <Link
      to={`/rooms/${slug}`}
      className="card card-noborder shadow"
      title="FEATURES"
    >
      <div className="card-tag">
        <span>{price} دولارًا</span>لليلة الواحدة
      </div>
      <div className="card-img">
        <div className="card-img-blurry" />
        <img
          src={images[0] || defaultImg}
          className="card-img-top"
          alt={name}
          loading="lazy"
        />
      </div>
      <div className="card-footer text-center text-capitalize">{name}</div>
    </Link>
  );
};

Room.propTypes = {
  room: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    price: PropTypes.number.isRequired
  })
};

export default Room;
