export const Menus = {
  MainMenu: [
    {
      item: "الرئيسية",
      slug: "/"
    },
    {
      item: "كل الغرف",
      slug: "/rooms"
    }
  ]
};

export const HomeBanner = {
  title: "غرف فخمة",
  subtitle: "غرف فاخرة تبدأ أسعارها من 299 دولارًا",
  actionLabel: "كل الغرف",
  actionLink: "/rooms"
};

export const ErrorPage = {
  title: "404 خطأ !",
  subtitle:
    "لم يتم العثور على عنوان URL المطلوب على الخادم. إذا قمت بإدخال عنوان URL يدويًا ، فيرجى التحقق من التهجئة والمحاولة مرة أخرى.",
  actionLabel: "العودة للرئيسية",
  actionLink: "/"
};
